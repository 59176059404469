.main-slider {
  margin-bottom: 0;
  text-align: right;
  overflow: hidden;
  .slick-dots {
    width: auto;
    bottom: auto;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    li {
      display: flex; } } }
.main-slid {
  display: block; }
.slide-img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 650px; }
//.main-slid
//  display: inline-block
//  width: -webkit-calc(100% - 215px)
//  width: calc(100% - 215px)
//  max-width: 1700px
//  text-align: left
//  position: relative
//  height: 610px
//  .text-container
//    position: absolute
//    top: 120px
//    left: 0
//    width: 100%
//    max-width: 570px
//    z-index: 1
//.slide-img
//  position: absolute
//  bottom: 0
//  right: 0
//  z-index: 0
//  transition: transform 2000ms cubic-bezier(0.215,.61,.355,1)
//  &:hover
//    transform: scale(1.03)
//.slide-headline
//  margin-bottom: 30px
//  +font-size(36,36)
//  color: $style-green-color
//  +font-bold
//  text-transform: uppercase
//  letter-spacing: 2px
//.slide-text
//  margin-bottom: 25px
//  +font-size(18,36)
//  +font-normal
//  color: #4c4f52
//  height: 147px
//  overflow: hidden
.main-slider-section {
  margin-top: -60px; }

/*our-product*/
.our-products {
  .container {
    background-repeat: no-repeat;
    background-position: center; } }
.maine-product-slider {
  width: 100%;
  max-width: 885px;
  margin: 15px auto 0;
  background-color: transparent; }
.main-product-slide {
  text-align: center;
  .img-container {
    img {
      margin: 0 auto;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: scale(1.03); } } } }

.maine-product-headline {
  display: inline-block;
  @include font-size(30,30);
  @include font-normal;
  color: #707274;
  text-decoration: none;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 10px;
  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: $style-brown-color;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); } }

/*recipes*/
.recipes-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  padding-bottom: 100px;
  .container {
    padding-bottom: 45px; } }
.recipes-slider {
  padding-bottom: 20px;
  .slick-dots {
    bottom: -30px; }
  .slick-slide {
    padding: 15px 0; } }
.recipes-slider-item {
  width: 100%;
  font-size: 0;
  border: 1px solid $border-color;
  background-color: #fff;
  transform: scale(0.8);
  @include transition(all, 0.7);
  height: 370px;
  .slick-center & {
    transform: scale(1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.10);
    @include transition(all, 0.7); } }
.recipes-item-part {
  display: inline-block;
  vertical-align: top;
  &.img-container {
    width: 410px;
    height: 100%;
    overflow: hidden;
    position: relative;
    a {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: scale(1.03); } } }


  &.text-container {
    width: -webkit-calc(100% - 450px);
    width: calc(100% - 450px);
    padding-top: 30px;
    padding-right: 25px;
    padding-left: 15px; } }
.recipes-slide-headline {
  margin-bottom: 35px;
  @include font-size(24,24);
  @include font-bold;
  color: $headline-color;
  max-height: 73px;
  overflow: hidden; }
.recipes-description {
  margin-bottom: 65px;
  @include font-size(16,24);
  max-height: 72px;
  overflow: hidden; }

/*news-section*/
.news-section {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top center;
  .container {
    padding-top: 85px; }
  .section-headline {
    margin-bottom: 0; }
  .row-inline {
    margin: 0 -30px; }
  .col-inline-xs-4 {
    padding: 0 30px; }
  .btn-container {
    margin-top: 35px; } }
.news-item {
  width: 100%;
  .img-container {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    overflow: hidden;
    position: relative;
    border: 1px solid $border-color;
    background-color: #fff;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.03); } } }
  .text-container {
    padding: 20px 10px 0; }
  time {
    display: block;
    margin-bottom: 20px;
    @include font-size(14,14);
    color: $style-green-color; } }
.news-item-headline {
  display: block;
  margin-bottom: 20px;
  @include font-size(18,18);
  @include font-bold;
  color: $headline-color;
  text-decoration: none;
  overflow: hidden;
  height: 55px;
  @include transition(opacity);
  &:hover {
    opacity: 0.7; } }
.news-description {
  height: 95px;
  @include font-size(16,24);
  overflow: hidden; }

/*about-section*/
.about-section {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom; }
.about-row {
  margin: 0 -45px; }
.about-col {
  padding: 0 45px; }
.about-item-headline {
  @include font-size(26,26);
  @include font-bold;
  color: $headline-color;
  padding-bottom: 15px;
  border-bottom: 1px solid $border-color; }
.about-item-icon {
  transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
  &:hover {
    transform: scale(1.1); } }

.about-item-description {
  @include font-size(16,16);
  padding-top: 10px;
  p {
    margin-top: 15px;
    img {
      margin: 0 5px; }
    &:first-child {
      margin-top: 0; } } }

/*subscription*/
.subscription {
  .content-container {
    background-color: #ebe7e4;
    padding: 25px 15px;
    .col-inline-xs-3 {
      padding: 0; } } }
.subscription-headline {
  display: inline-block;
  @include font-size(31,35);
  color: $headline-color;
  span {
    display: block;
    @include font-size(36,36);
    @include font-bold; } }
.subscription-form {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    @include font-size(18,18);
    padding: 15px 65px 15px 30px;
    background-color: #fff;
    box-shadow: 0 0 27px rgba(135, 113, 77, 0.15);
    border-radius: $radius;
    border: 1px solid transparent;
    &::-webkit-input-placeholder {
      color: #c2c2c3; }
    &::-moz-placeholder {
      color: #c2c2c3; }
    &::-ms-input-placeholder {
      color: #c2c2c3; }
    &.invalid {
      border-color: rgba(255, 3, 10, 0.45); } }
  button {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: none;
    background-color: $style-brown-color;
    &:before {
      content: '';
      display: inline-block;
      width: 29px;
      height: 21px;
      @include sprite('sprite.svg',-112px, -30px);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -14px;
      margin-top: -10px; }
    &:hover {
      &:before {
        animation: shake 2s infinite ease-in-out; } } } }

/*popup*/
#notice-popup {
  .popup-content {
    max-width: 700px;
    margin: 0 auto;
    background-color: #ebe7e4;
    padding: 60px 15px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom right; }
  .popup-title {
    @include font-size(36,36);
    @include font-bold;
    color: $headline-color;
    margin-bottom: 45px; }
  .description {
    @include font-size(18,24);
    margin-bottom: 60px; }
  &.error {
    .popup-title {
      color: rgba(255, 3, 10, 0.6); } } }

