.start-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfaf9;
  background-size: 100%;
  width: 900px;
  margin: 0 auto;
  border-radius: 0 0 40px 40px;
  box-shadow: 0 10px 50px 15px black; }
.start-content {
  width: 100%;
  max-width: 960px;
  text-align: center;
  padding: 0; }
.transition-language-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 26px;
  background: $style-green-color; }
.transition-language-container .language-link-wrapper {
  background: #005b2a;
  border-radius: $radius;
  margin: 30px 40px; }
.transition-language-container .language-link {
  display: inline-block;
  padding: 10px 0;
  margin: 0 15px;
  @include font-size(18,18);
  @include font-normal;
  color: white;
  text-align: center;
  border: 1px solid transparent;
  border-radius: $radius;
  text-decoration: none;
  @include transition(all);
  &.active {
    margin: 0;
    width: 100px;
    color: $style-green-color;
    background: $primary-background;
    @include font-bold; } }
.logo-container {
  margin-bottom: 50px;
  img {
    width: 100%;
    max-width: 280px;
    margin-top: -70px; } }
.country-selection-headline {
  margin-bottom: 45px;
  @include font-size(36,36);
  color: $headline-color; }
.country-selection {
  width: 100%;
  max-width: 485px;
  position: relative;
  margin: 0 auto;
  input {
    width: 100%;
    margin-bottom: 45px;
    @include font-size(18,18);
    padding: 15px 65px 15px 30px;
    background-color: #fff;
    box-shadow: 0 0 27px rgba(135, 113, 77, 0.15);
    border-radius: $radius;
    border: none;
    &::-webkit-input-placeholder {
      color: #c2c2c3; }
    &::-moz-placeholder {
      color: #c2c2c3; }
    &::-ms-input-placeholder {
      color: #c2c2c3; } }
  .pseudo-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: none;
    background-color: #c8c8c8;
    &:before, &:after {
      content: '';
      display: block;
      width: 19px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      top: 50%;
      margin-top: -2px; }

    &:after {
      transform: rotate(90deg); } } }
.country {
  margin-bottom: 20px;
  @include font-size(24,24);
  @include font-bold;
  color: $style-green-color; }
.show-main {
  width: 290px;
  margin-bottom: 60px; }
.country-select {
  position: absolute;
  width: 100%;
  left: 0;
  top: 53px;
  background-color: #fff;
  text-align: left;
  padding: 15px 30px;
  li {
    padding: 10px 0; } }



