header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $style-green-color;
  z-index: 10;
  &.small {
    .top-header {
      height: 0;
      padding: 0;
      overflow: hidden; }
    .header-logo {
      max-width: 112px; } } }

.header-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1155px;
  .row-inline {
    margin: 0 -5px;
    [class^=col-inline-xs] {
      padding-left: 5px;
      padding-right: 5px; } } }

.top-header {
  padding: 10px 0;
  height: 60px;
  @include transition(all); }

.soc-link {
  margin-right: 30px;
  display: inline-block;
  width: 20px;
  height: 20px;
  @include sprite('sprite.svg',0,0);
  @include transition(opacity);
  &:hover {
    opacity: 0.6; }
  &.fb {
    background-position: -84px 0; }
  &.ins {
    background-position: -53px 1px; }
  &.yt {
    background-position: -153px 0; }
  &.twit {
    background-position: -116px -1px; } }

.header-nav {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px; }

.dropdown {
  z-index: 1;
  button {
    padding: 16px;
    background-color: transparent;
    border: none;
    @include font-size(14,14);
    @include font-normal;
    color: #fff;
    border-radius: $radius;
    position: relative;
    z-index: 1;
    @include transition(all, 0.2, 0.2); }
  &.active {
    button {
      background-color: #fff;
      color: $style-green-color;
      @include transition(all, 0.2); }
    .dropBox-caret {
      @include rotate(180);
      border-color: $style-green-color transparent transparent transparent;
      @include transition(all, 0.2); }
    .dropdown-menu {
      width: 225px;
      padding: 19px 19px 19px 25px;
      opacity: 1; } } }

.dropBox-caret {
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 3.5px 0 3.5px;
  border-color: #ffffff transparent transparent transparent;
  @include transition(all, 0.2, 0.2); }

.dropdown-menu {
  min-width: auto;
  overflow: hidden;
  width: 0;
  left: auto;
  right: 0;
  top: -2px;
  padding: 0;
  text-align: left;
  z-index: 0;
  @include font-size(12,12);
  @include font-normal;
  background-color: #1f592f;
  border-radius: $radius;
  border: none;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
  opacity: 0;
  @include transition(all, 0.5);
  li {
    display: inline-block;
    margin-right: 10px;
    a {
      color: #fff;
      text-decoration: none;
      @include transition(opacity);
      &:hover {
        opacity: 0.6; } } } }

#country.active {
  .dropdown-menu {
    width: 400px;
    opacity: 1;
    padding: 0;
    overflow: hidden; } }

.dropdown-menu {
  input {
    width: 100%;
    padding: 17px 25px;
    background-color: #1f592f;
    border: none;
    @include font-size(12,16.8);
    @include font-normal;
    color: #fff; } }

.country-select.live-search {
  display: none;
  padding: 0 25px 15px;
  max-height: 150px;
  overflow-y: auto;
  text-align: left;
  position: relative;
  background-color: transparent;
  top: 0; }

li.live-search-item {
  margin: 15px 0;
  color: #fff;
  display: block;
  @include transition(opacity);
  &:hover {
    opacity: 0.7; } }

.live-search-not-found {
  display: none;
  padding: 0 25px 15px;
  color: #fff;
  text-align: left;
  margin-top: 10px; }

.right-header-col {
  position: static; }

.header-search {
  margin-left: 0;
  form {
    display: inline-block;
    &.open {
      .search-btn {
        background-color: #fff;
        @include transition(all, 0.2);
        &:before {
          background-position: -220px -26px; } }
      .search-input-container {
        width: 99%;
        opacity: 1; } } } }

.search-btn {
  width: 46px;
  height: 46px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  @include transition(all, 0.2, 0.2);
  &:before {
    content: '';
    display: inline-block;
    width: 21px;
    height: 21px;
    @include sprite('sprite.svg',-188px, 0); } }

.search-input-container {
  width: 0;
  opacity: 0;
  position: absolute;
  top: -2px;
  right: 4px;
  border-radius: $radius;
  overflow: hidden;
  z-index: 1;
  @include transition(all, 0.5);
  input {
    width: 100%;
    padding: 17px 60px;
    background-color: #1f592f;
    border: none;
    @include font-size(12,16.8);
    @include font-normal;
    color: #fff;
    border-radius: $radius; } }

.menu-col {
  padding: 20px 0;
  border-top: 2px solid $menu-border-color; }

.main-menu {
  display: flex;
  justify-content: space-between; }

.menu-item {
  &:before {
    content: '';
    margin-right: 12px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $style-brown-color;
    vertical-align: middle; } }

.menu-link {
  @include font-size(18,18);
  @include font-normal;
  color: #fff;
  text-decoration: none;
  @include transition(opacity);
  &:hover {
    opacity: 0.6; } }

.header-logo {
  width: 100%;
  max-width: 280px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  @include transition(all);
  z-index: 2;
  text-align: center;
  img {
    width: 100%;
    max-width: 245px;
    height: auto; } }


.mobile-header {
  display: none; }

.menu-btn-container {
  padding: 10px 0;
  border-bottom: 2px solid #006334;
  margin: 0 -15px;
  text-align: center; }
.menu-btn {
  display: inline-block;
  width: 23px;
  height: 16px;
  position: relative;
  cursor: pointer;
  @include sprite('sprite.svg',-152px, -27px);
  @include transition(transform); }
.mob-logo {
  text-align: center;
  height: 80px;
  img {
    max-width: 250px;
    position: relative;
    top: 20px;
    @include transition(max-width);
    z-index: 9; } }



