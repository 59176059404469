@media (max-height: 855px) {
  .transition-language-container {
    margin-bottom: 35px; }
  .logo-container {
    margin-bottom: 30px;
    img {
      max-width: 420px; } }
  .country-selection-headline {
    margin-bottom: 20px; }
  .country-selection {
    input {
      margin-bottom: 20px; } }
  .country {
    margin-bottom: 20px; }
  .error-page {
    height: auto !important;
    padding-top: 100px;
    padding-bottom: 30px;
    img {
      width: 65%; } }
  .code {
    @include font-size(90,90); }
  .about-error {
    @include font-size(24,24); } }
@media (max-height: 525px) {
  .start-page {
    height: auto !important;
    padding: 25px 0; }
  .transition-language-container {
    margin-bottom: 15px; }
  .logo-container {
    margin-bottom: 15px;
    img {
      max-width: 200px; } }
  .country-selection-headline {
    margin-bottom: 15px;
    @include font-size(20,20); }
  .country-selection {
    input {
      margin-bottom: 15px;
      @include font-size(14,14); }
    .pseudo-btn {
      width: 43px;
      height: 43px; } }
  .country {
    margin-bottom: 15px;
    @include font-size(18,18); } }


//@media (max-height: 825px) and (max-width: 1370px)
//  .main-slider
//    height: 555px
//  .main-slid
//    height: 555px
//  .slide-img
//    width: 75%


@media (max-width: 1425px) {
  .next-section {
    left: 25px; }
  //.main-slid
  //  width: -webkit-calc(100% - 75px)
  //  width: calc(100% - 75px)
  .recipe-headline-row {
    margin: 0 -15px;
    .col-inline-xs-6 {
      padding: 0 15px; } } }

@media (max-width: 1280px) {
  //.main-slider
  //  height: 530px
  //.main-slid
  //  height: 530px
  //.slide-img
  //  width: 70%
  .our-products {
    .container {
      background-size: 100%; } }
  .recipes {
    background-size: 100%; }
  .recipe-headline-row {
    margin: 0 -10px;
    .col-inline-xs-6 {
      padding: 0 10px; } }
  .recommend-link {
    margin-left: 10px;
    @include font-size(16,16); }
  .recommend-link-icon {
    margin-right: 5px; } }

@media (max-width: 1190px) {
  .bay-row .select-col {
    padding: 35px 25px 35px 15px; } }
@media (max-width: 1090px) {
  //.main-slider
  //  height: 500px
  //.main-slid
  //  height: 500px
  //  .text-container
  //    max-width: 450px
  //.slide-headline
  //  +font-size(24,24)
  //.slide-text
  //  height: 120px
  //  +font-size(16,36)
  .section-headline {
    @include font-size(24,24); }
  .maine-product-headline {
    @include font-size(24,24); }
  .about-row {
    margin: 0 -15px; }
  .about-col {
    padding: 0 15px; }
  .about-item-headline {
    @include font-size(20,20); }
  .subscription-headline {
    @include font-size(24,27);
    span {
      @include font-size(30,30); } }
  .footer-row .col-inline-xs-3 {
    padding: 20px 15px 20px 20px; }
  .soc-links .soc-link {
    margin-right: 15px; }
  .nav-catalog-headline {
    padding-left: 15px; }
  .nav-catalog-item {
    padding-left: 15px; }
  .nav-catalog-body {
    padding-left: 20px; }
  .nav-catalog-sub_item {
    padding: 5px 0; }
  .about-product .container {
    background-size: 100%; }
  .product-img-col {
    padding-left: 0; }
  .product-data-col {
    padding-left: 25px; }
  .recipe-headline-section .container {
    background-size: 100%; }
  .catalog-recipe-item .catalog-item-headline {
    @include font-size(20,20);
    height: 79px; }
  .pagination li {
    @include font-size(20,20); }
  .headline-block {
    .btn {
      opacity: 1;
      visibility: visible; } }
  .other-item {
    .img-container {
      height: 190px; } } }

@media (max-width: 1024px) {
  section {
    background-image: none !important;
    .container {
      background-image: none !important; } }
  .content_page {
    padding-top: 35px; }
  .main-slider-section {
    margin-top: -35px; }

  .menu-link {
    @include font-size(16,16); }
  .header-logo {
    max-width: 230px; }
  //.main-slider
  //  height: 400px
  //.main-slid
  //  height: 400px
  //  .text-container
  //    padding-left: 15px
  //.slide-headline
  //  margin-bottom: 15px
  .next-section {
    display: none; }
  //.main-slid
  //  width: 100%
  //.slide-img
  //  width: 60%
  .recipes {
    padding-bottom: 60px; }
  .recipes-slider-item {
    transform: scale(1);
    .slick-slide {
      padding: 0 50px; } }
  .news-section {
    background-size: 100%;
    .container {
      padding-top: 40px; }
    .row-inline {
      margin: 0 -15px; }
    .col-inline-xs-4 {
      padding: 0 15px; } }
  .about-section {
    background-size: 100%; }
  .footer-menu-col {
    padding-left: 15px; }
  .footer-logo-col {
    padding-right: 15px; }
  .footer-menu-link {
    @include font-size(18,18); }
  .parameter {
    @include font-size(14,14); }
  .value {
    @include font-size(14,14); }
  .recipe-with-product {
    margin-top: 20px;
    .text-container {
      padding: 20px 45px 20px 30px; } }
  .block-title {
    margin-bottom: 20px; }
  .recipe-with-product .headline {
    margin-bottom: 20px;
    @include font-size(20,20); }
  .recipe-with-description {
    margin-bottom: 20px; }
  .recipe-tagline {
    padding-top: 50px; }
  .recipe-ingredients-title {
    @include font-size(20,20); }
  .recipe-ingredient-item {
    @include font-size(16,16);
    margin-bottom: 15px; }
  .recipe-ingredients-list {
    margin-bottom: 35px; }
  .preparation-headline {
    @include font-size(24,24);
    margin-bottom: 25px; }
  .preparation-step {
    padding: 15px; }
  .step-number {
    padding: 0 10px 5px;
    @include font-size(36,36); }
  .step-text {
    @include font-size(16,16); }
  .recipe-tagline {
    @include font-size(30,30); }
  .catalog-item {
    height: 290px; }
  .catalog-item-headline {
    margin-top: 20px; }
  .category-product .img-container {
    height: 175px; }
  .about-company-headline {
    @include font-size(20,20); }
  .values-title {
    @include font-size(20,20); }
  .values-item-img {
    margin-bottom: 10px; }
  .confidence-text {
    @include font-size(30,30); }
  .trademark-headline {
    @include font-size(20,20); }
  .contact-item {
    padding: 0 10px; }
  .news-catalog {
    .row-inline {
      margin: 0 -15px; }
    .col-inline-xs-4 {
      padding: 0 15px;
      margin-bottom: 30px; } }
  .row-headline {
    @include font-size(20,20); }
  .search-result-container {
    margin-top: 10px; }
  .scroll-table {
    min-width: 850px; } }

@media (max-width: 945px) {
  .content_page {
    padding-top: 30px; }
  .main-slider-section {
    margin-top: -30px; }
  .start-page {
    width: 730px; }
  .menu-link {
    @include font-size(14,14); }
  .header-logo {
    max-width: 190px; }
  .about-product {
    .row-inline {
      margin-top: 25px; }
    [class^=col-inline-xs] {
      width: 100%; } }
  .nav-catalog {
    top: 0;
    width: 100%; }
  .nav-catalog-container {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 2;
    left: 0;
    top: 100%; }
  .product-block-other .other-item .img-container {
    height: 160px; }
  .recipe-with-product {
    .img-container {
      width: 320px; }
    .text-container {
      width: -webkit-calc(100% - 320px);
      width: calc(100% - 320px); } }
  .nav-catalog-headline_arrow {
    display: block; }
  .product-name {
    margin-bottom: 20px; }
  .product-about {
    margin-bottom: 20px; }
  .ingredients-container {
    margin-bottom: 20px; }
  .other-col {
    width: 25%;
    &:nth-child(3) {
      display: none; } }
  .portion:before {
    margin-right: 15px; }
  .category-row {
    .nav-col {
      width: 100%; }
    .content-col {
      width: 100%;
      margin-top: 20px; } }
  .category-product {
    margin: 0 -10px; }
  //.main-slider
  //  height: 340px
  //.main-slid
  //  height: 340px
  //  .text-container
  //    top: 50px
  .schema {
    margin: 20px auto 0;
    max-width: 790px;
    padding: 0 10px; }
  .schema-row {
    .col-inline-xs-12 {
      @include font-size(14,12);
      height: 45px; }
    .col-inline-xs-4 {
      @include font-size(14,12); } }
  .schema-item {
    height: 45px; }
  .plus-item {
    padding: 0 35px; }
  .catalog-recipe-item {
    height: 290px; }
  .hot-line a {
    @include font-size(20,20); } }

@media (max-width: 865px) {
  .logo-col {
    width: 25%; }
  .menu-col {
    width: 37.5%; }
  .header-nav {
    margin-left: 0; }
  .category-links-container {
    width: 100%; }
  .category-links-item {
    width: 122px; }
  .section-headline {
    margin-bottom: 30px; }
  .recipes-slide-headline {
    @include font-size(20,20);
    max-height: 62px; }
  .footer-row {
    display: block;
    [class^=col-inline-xs] {
      width: 100%; }
    .col-inline-xs-3 {
      font-size: 0;
      div {
        display: inline-block;
        vertical-align: middle;
        width: 33.33333%; } } }
  .soc-links {
    text-align: center;
    margin: 15px 0; }
  .development {
    text-align: right; }
  .recommends-product {
    @include font-size(16,16);
    &:before, &:after {
      transform: scale(0.5); } }
  .about-company-item {
    margin-bottom: 25px; }
  .values-item-img {
    width: 125px; }
  .popup-title {
    margin-bottom: 25px;
    @include font-size(24,28); }
  .contact-item {
    display: block;
    width: 100%;
    margin-top: 20px;
    &:first-child {
      margin-top: 0; } }
  .news-img {
    margin-bottom: 25px; }

  .recommend-block {
    margin-top: 25px;
    .recommend-container {
      @include font-size(18,18); }
    .recommend-link {
      @include font-size(14,14);
      margin-left: 21px; } }
  .headline-block .btn {
    width: 150px;
    padding: 10px 5px;
    @include font-size(14,14); }
  .region-item {
    margin-top: 20px; }
  .bay-row .select-col {
    width: 50%; }
  .article-section {
    img, .text-container {
      width: 100%; } }
  .about-category {
    .img-container {
      width: 100%;
      text-align: center; }
    .text-container {
      width: 100%; } }
  .product-img-col {
    width: 100%;
    max-width: 320px;
    display: block;
    margin: 0 auto; }
  .product-img-slide {
    text-align: center;
    img {
      display: inline-block; } }
  .product-img-preview {
    display: none; }
  .product-data-col {
    padding-left: 0;
    width: 100%; } }
@media (max-width: 825px) {
  .catalog-recipe-item {
    height: 240px;
    .catalog-item-headline {
      @include font-size(18,18);
      height: 73px; }
    .img-container {
      height: -webkit-calc(100% - 81px);
      height: calc(100% - 81px); } } }
@media (max-width: 795px) {
  .start-page {
    width: 100%; }
  .content_page {
    padding-top: 45px; }
  .main-slider-section {
    margin-top: -45px; }
  header {
    position: relative;
    &.open {
      .mob-logo img {
        max-width: 112px;
        top: 10px; }
      .menu-btn {
        background-position: -187px -23px;
        transform: rotate(90deg); } } }
  .header-container {
    display: none; }
  .mobile-header {
    display: block; }
  .top-header {
    border-bottom: 2px solid $menu-border-color;
    .col-inline-xs-6 {
      text-align: center; } }
  .header-search {
    form {
      position: relative;
      &.open .search-input-container {
        width: 615px; } } }
  .bottom-header {
    padding: 5px 0 20px;
    border-bottom: 2px solid #296138; }
  .menu-col {
    width: 100%;
    border-top: none;
    padding: 0; }
  .main-menu {
    display: block;
    text-align: center; }
  .menu-item {
    padding-top: 15px;
    &:before {
      display: block;
      margin: 0 auto 10px; } }
  .menu-link {
    @include font-size(18,18); }
  .logo-col {
    display: none; }
  .slide-text {
    @include font-size(16,24);
    height: 115px; }
  .recipes-slider .slick-slide {
    padding: 0 15px; }
  .news-item {
    time {
      margin-bottom: 10px; } }
  .news-item-headline {
    @include font-size(16,16);
    margin-bottom: 10px;
    height: 49px; }
  .news-description {
    @include font-size(14,21);
    height: 85px; }
  .recipe-headline-row {
    .col-inline-xs-6 {
      width: 100%; } }
  .recipe-img {
    .img-container {
      padding-bottom: 50%;
      margin-bottom: 25px; } }
  .recipe-headline {
    margin-bottom: 25px; }
  .portion {
    margin-bottom: 25px; }
  .recommend-container {
    padding-top: 15px; }
  .recipe-headline-row {
    padding-top: 35px; }
  .content-container {
    .col-inline-xs-4 {
      width: 50%; } }
  .category-product {
    .col-inline-xs-4 {
      width: 50%; } }
  .about-company-title span {
    @include font-size(20,20); }
  //.main-slid .text-container
  //  top: 70px
  .row-headline {
    @include font-size(18,18); }
  .schema-row .col-inline-xs-4 {
    width: 33.3333%; }
  .partners-page .col-inline-xs-4 {
    width: 100%; }
  .recipe-block-other {
    .row-inline {
      margin: 0 -5px; } }
  .other-col {
    padding: 0 5px; }
  .other-item .img-container {
    height: 140px; }
  .other-item-name {
    @include font-size(16,16); } }


@media (max-width: 715px) {
  .recipes-item-part {
    &.img-container {
      width: 350px; }
    &.text-container {
      width: -webkit-calc(100% - 350px);
      width: calc(100% - 350px); } }
  .recipes-slide-headline {
    margin-bottom: 20px; }
  .recipes-description {
    margin-bottom: 30px; }
  .recipe-with-product {
    .img-container {
      width: 225px; }
    .text-container {
      width: -webkit-calc(100% - 225px);
      width: calc(100% - 225px); } }
  .bay-row {
    display: block;
    [class^=col-inline-xs-] {
      width: 100%; }
    .select-col {
      padding: 25px 25px 0;
      text-align: center; } }
  .download-block {
    display: block;
    margin-top: 20px;
    &:last-child {
      margin-left: 0; } }
  .item-container:before {
    top: -3px; }
  .partners-row {
    padding-bottom: 25%;
    .img-container {
      width: 65%; } }
  .headline-block {
    width: 35%; }
  .select-container {
    max-width: none; }
  .bay-row .select-col .sbHolder {
    width: 45%;
    display: inline-block;
    margin: 0 2%; }
  .region-info {
    max-width: none;
    display: inline-block; }
  .region-item {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin: 20px 2%; }
  .region-item-headline {
    @include font-size(16,16); }
  .region-subitem {
    @include font-size(14,21); }
  .map {
    min-height: auto;
    height: 440px; }
  .col-inline-xs-12.plus-row {
    flex-wrap: wrap;
    height: auto;
    padding: 15px 0; }
  .plus-item {
    width: 50%;
    padding: 10px;
    &:before {
      display: none; }
    &:last-child {
      &:before {
        display: block;
        top: 0; } } } }


@media (max-width: 690px) {
  .mob-logo {
    height: 50px;
    img {
      max-width: 120px;
      top: 0; } }
  .content_page {
    padding-top: 20px; }
  .header-search {
    form.open {
      .search-input-container {
        width: 400px; } } }
  .slide-text {
    @include font-size(14,21);
    height: 81px; }
  .recipes-item-part {
    &.img-container {
      width: 290px; }
    &.text-container {
      width: -webkit-calc(100% - 290px);
      width: calc(100% - 290px); } }
  .recipes-slide-headline {
    @include font-size(18,18);
    max-height: 56px; }
  .recipes-description {
    @include font-size(14,21);
    max-height: 63px; }
  .recipes-slider {
    padding-bottom: 0; }
  .news-section {
    .col-inline-xs-4 {
      width: 50%;
      &:last-child {
        display: none; } } }
  .subscription-row {
    [class^=col-inline-xs] {
      width: 100%;
      text-align: center;
      margin-bottom: 15px; } }
  .subscription-headline {
    span {
      display: inline-block; } }
  .footer-logo-col {
    width: 40%; }
  .footer-menu-col {
    width: 60%; }
  .footer-menu-item {
    width: 50%;
    padding: 10px 0; }
  .footer-menu-link {
    @include font-size(16,16); }
  .transition-language-container {
    .language-link {
      width: 110px;
      @include font-size(14,14); } }
  .logo-container img {
    width: 290px; }
  .country-selection-headline {
    @include font-size(24,24); }
  .country {
    @include font-size(20,20); }
  .recipe-ingredients-list {
    margin-bottom: 20px; }
  .preparation-row .col-inline-xs-4 {
    width: 50%; }
  .about-category .text-container {
    @include font-size(14,21); }
  .about-section-row .col-inline-xs-6 {
    width: 100%;
    &:first-child {
      height: 315px; } }
  .about-company-title {
    padding-bottom: 40%; }
  .other-col {
    width: 50%;
    margin: 15px 0; }
  .dropdown button {
    padding: 16px 5px; }
  .show-main {
    display: block;
    margin: 0 auto 10px; } }



@media (max-width: 545px) {
  .recipes-slider-item {
    height: 445px; }
  .recipes-item-part {
    display: block;
    &.img-container {
      margin: 0 auto;
      height: 180px; }
    &.text-container {
      width: 100%;
      padding: 20px;
      text-align: center; } }
  .about-item-icon {
    width: 65px; }
  .about-item-headline {
    @include font-size(18,18); }
  .about-item-description {
    @include font-size(14,14); }
  .footer-row .col-inline-xs-3 div {
    width: 100%;
    text-align: center; }
  .product-name {
    @include font-size(24,24); }
  .product-about {
    @include font-size(14,21); }
  .in-product-item {
    margin-right: 10px; }
  .product-img-slide img {
    width: 50%; }
  .recipe-with-product {
    display: block;
    .img-container {
      width: 100%;
      height: 147px; }
    .text-container {
      width: 100%; } }
  .portion {
    @include font-size(18,18);
    &:before {
      margin-right: 5px;
      transform: scale(0.7); } }
  .step-number {
    @include font-size(30,30); }
  .catalog-item {
    height: 240px; }
  .recommends-product {
    padding: 0; }
  .bay-row .select-col select {
    margin-bottom: 35px; }
  .map {
    height: 380px; }
  .category-product .img-container {
    height: 150px; }
  .download-block-item {
    margin-left: 20px;
    margin-bottom: 15px;
    .text-container {
      margin-left: 5px; } }
  .about-section-row .col-inline-xs-6:first-child {
    height: 260px; }
  .confidence-text {
    @include font-size(24,24); }
  .catalog-recipe-item {
    height: 240px; }
  .pagination li {
    margin: 0 10px;
    @include font-size(18,18); }
  .article-section .text-container {
    columns: 1; }
  .about-trademark-category {
    columns: 1; }
  .scroll-table th div {
    @include font-size(12,12); }
  .recommend-block {
    padding: 20px 0; }
  .partners-row {
    padding-bottom: 30%;
    .img-container {
      &:before {
        left: -9px;
        border-width: 10px 10px 10px 0; } } }
  .row-headline {
    @include font-size(16,16); }
  .input-container {
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
    padding-right: 15px; }
  .search-btn-container {
    width: 100px;
    .btn {
      width: 100px; } } }



@media (max-width: 520px) {
  .start-page {
    width: 100%; }
  .top-header {
    height: auto;
    .col-inline-xs-6 {
      text-align: center;
      width: 100%; } }
  .header-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    form {
      position: static;
      &.open .search-input-container {
        width: 97%;
        input {
          padding: 19px 60px 19px 20px; } } } }
  .search-btn {
    position: absolute;
    right: 5px;
    top: 0; }
  .soc-networks {
    margin-top: 10px; }
  .language-container, .country-container {
    display: block; }
  .dropdown-menu {
    top: 49%;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    text-align: center;
    li {
      display: block;
      margin: 15px 0;
      @include font-size(16,16);
      &:first-child {
        margin-top: 0; } } }
  .dropdown {
    position: relative;
    z-index: 0; }
  .dropdown.active {
    z-index: 1;
    .dropdown-menu {
      width: 100%;
      height: auto;
      padding: 40px 19px 19px 25px; } }
  #country.active {
    .dropdown-menu {
      width: 100%;
      padding: 40px 19px 19px 25px;
      overflow: hidden;
      input {
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-radius: 25px; } } }

  .main-slider {
    //height: 470px
    .slick-dots {
      right: 25px; } }
  //.main-slid
  //  height: 470px
  //  .text-container
  //    top: 67px
  //    max-width: none
  //    padding: 0 15px
  //    position: relative
  //    text-align: center
  //    .btn
  //      position: absolute
  //      bottom: -257px
  //      left: 50%
  //      transform: translateX(-50%)
  //.slide-img
  //  width: 100%
  .recipes .container {
    padding-bottom: 25px; }
  .about-col {
    width: 50%; }
  .footer-logo-col {
    width: 30%;
    padding-right: 0; }
  .footer-menu-col {
    width: 70%; }
  .in-product-item {
    padding: 10px 15px; }
  .parameter {
    @include font-size(13,13); }
  .recipe-headline {
    @include font-size(24,19); }
  .recommend-container {
    @include font-size(14,14); }
  .recommend-link {
    @include font-size(14,14); }
  .recipe-ingredient-item {
    @include font-size(14,14); }
  .step-text {
    @include font-size(14,14); }
  .recipe-tagline {
    @include font-size(24,24); }
  .recipe-ingredients-title span {
    @include font-size(16,16); }
  .country-select.live-search {
    margin-top: 25px; }
  .about-company-title {
    padding-bottom: 45%;
    overflow: hidden;
    &:before {
      display: none; } }
  .values-item-img {
    width: 100px; }
  .article-section {
    padding-bottom: 25px;
    .text-container {
      @include font-size(14,21); } }
  .trademark-section {
    padding-top: 25px; }
  .trademark-headline {
    margin-bottom: 15px;
    padding-bottom: 15px; }
  .trademark-product-container {
    padding-top: 0; }
  .trademark-category-product {
    width: 112px;
    .img-container {
      height: 100px; } }
  .trademark-category {
    padding: 20px 0; }
  .about-trademark-category {
    margin-top: 25px; }
  .error-page {
    padding-top: 70px; }
  .headline-block {
    width: 45%; }
  .partners-row .img-container {
    width: 55%; }
  .bay-row .select-col .sbHolder {
    width: 100%;
    margin: 0; }
  .region-item {
    width: 100%;
    margin: 20px 0 0; }
  .region-item-headline {
    margin-bottom: 10px; }
  .region-subitem {
    margin-top: 15px; }
  .bay-row .select-col {
    padding: 25px; }
  .catalog-recipe {
    .col-inline-xs-4 {
      display: block;
      width: 100%;
      font-size: 0; } }
  .catalog-recipe-item {
    height: 135px;
    margin-top: 10px; }
  .catalog-recipe-item .catalog-item-headline {
    display: inline-block;
    @include font-size(14,14);
    padding: 0 10px 0;
    height: auto;
    vertical-align: middle;
    width: -webkit-calc(100% - 200px);
    width: calc(100% - 200px); }
  .catalog-recipe-item .img-container {
    display: inline-block;
    width: 200px;
    height: 100%;
    vertical-align: middle;
    margin-top: 0; } }

@media (max-width: 480px) {
  .logo-container img {
    width: 200px; }
  .country-select {
    height: 130px; }
  .recommends-product {
    padding: 10px 5px;
    &:before, &:after {
      display: none; } } }

@media (max-width: 390px) {
  .mob-logo {
    height: 50px; }
  .main-slider {
    //height: 390px
    .slick-dots {
      right: 10px;
      transform: translateY(0);
      top: 0; } }
  //.main-slid
  //  height: 390px
  //.slide-headline
  //  +font-size(14,14)
  //.slide-text
  //  +font-size(12,18)
  //  +font-normal
  //  height: 70px
  //.main-slid .text-container .btn
  //  bottom: -205px
  //  +font-size(14,14)
  .section-headline {
    padding: 15px 0;
    @include font-size(20,20);
    &:before, &:after {
      transform: scale(0.8); } }
  .category-link {
    @include font-size(11,11);
    padding: 10px; }
  .category-links-item {
    width: 95px;
    margin-bottom: 8px; }
  .maine-product-headline {
    @include font-size(20,20);
    margin-bottom: 25px; }
  .our-products .container {
    background-repeat: repeat; }
  .news-section {
    .col-inline-xs-4 {
      width: 100%;
      &:nth-last-child(2) {
        display: none; } } }
  .about-col {
    width: 100%; }
  .subscription-headline span {
    display: block; }
  .footer-logo-col {
    width: 100%;
    text-align: center; }
  .footer-logo {
    margin: 0 auto; }
  .footer-menu-col {

    width: 100%;
    margin-top: 20px;
    text-align: center; }
  .footer-menu-item {
    &:before {
      margin: 0 auto 10px;
      display: block; } }
  .recipe-with-product {
    .text-container {
      padding: 20px; }
    .headline {
      margin-bottom: 10px; } }
  .block-title {
    margin-bottom: 10px; }
  .preparation-row .col-inline-xs-4 {
    width: 100%; }
  .recommend-links-container {
    padding: 10px 0 0;
    vertical-align: top; }
  .recommend-link {
    display: block;
    margin-bottom: 10px;
    &:first-child {
      margin-left: 10px; } }
  .recipe-block-other .other-item .img-container {
    height: 160px; }
  .content-container .col-inline-xs-4 {
    padding: 0 5px; }
  .catalog-item {
    height: 180px;
    margin-bottom: 10px; }
  .catalog-item-headline {
    @include font-size(14,14);
    &:before {
      width: 30px;
      margin-left: -15px; } }
  .recommends-product {
    padding: 10px 5px;
    @include font-size(14,14); }
  .bay-row .select-col select {
    margin-bottom: 20px; }
  .category-product {
    .col-inline-xs-4 {
      padding: 0 5px; }
    .img-container {
      height: 95px; } }
  .about-section-row .col-inline-xs-6:first-child {
    height: 190px; }
  .confidence-text {
    @include font-size(20,20); }
  .values-row .col-inline-xs-4 {
    width: 100%; }
  .download-section .container {
    text-align: left; }
  .download-block-title {
    display: block;
    margin-bottom: 10px; }
  .about-company-description {
    @include font-size(14,14); }
  .popup-title {
    @include font-size(18,22); }
  .pagination li {
    @include font-size(16,16); }
  .section-headline span {
    display: inline-block;
    width: 190px;
    margin: 0; }
  .recommend-block {
    text-align: left;
    padding: 20px 10px; }
  .recommend-block .recommend-link {
    margin-left: 0;
    &:first-child {
      margin-left: 0; } }
  .partners-row {
    padding-bottom: 0;
    height: auto;
    display: block;
    .img-container {
      width: 100%;
      position: relative;
      &:before {
        left: 50%;
        transform: translateX(-50%);
        top: -9px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent; }
      img {
        width: 100%;
        height: auto; } } }

  .headline-block {
    width: 100%;
    position: static;
    padding: 15px 15px 25px; } }

@media (max-width: 350px) {
  .catalog-recipe-item {
    height: 120px;
    .catalog-item-headline {
      width: -webkit-calc(100% - 150px);
      width: calc(100% - 150px); }
    .img-container {
      width: 150px; } }
  .news-catalog {
    .row-inline {
      margin: 0 -5px; }
    .col-inline-xs-4 {
      width: 100%;
      margin-bottom: 20px; } }
  .input-container {
    width: 100%;
    margin-bottom: 15px; }
  .result-div {
    text-align: center; }
  .search-btn-container {
    width: 100%;
    text-align: center; }
  .search-result-item {
    .text-container {
      @include font-size(14,16);
      max-height: 47px; }
    .number {
      @include font-size(18,18);
      width: 30px; }
    .text-container {
      width: -webkit-calc(100% - 30px);
      width: calc(100% - 30px); } }
  .search-result-name {
    @include font-size(14,14); }
  .schema-row {
    .col-inline-xs-12 {
      @include font-size(12,10); }
    .col-inline-xs-4 {
      @include font-size(12,10); } } }


