.recipe-headline-section {
  .container {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top center; } }

.recipe-headline-row {
  margin: 0 -20px;
  padding-top: 65px;
  .col-inline-xs-6 {
    padding: 0 20px; } }

.recipe-img {
  .img-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.03); } } } }

.zoom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.40);
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    @include sprite('sprite.svg',-249px, -3px); } }

.recipe-category {
  display: inline-block;
  margin-bottom: 25px;
  padding: 5px 16px;
  background-color: #ebe7e4;
  border-radius: $radius;
  @include font-size(11,11);
  @include font-normal;
  color: #a6a6a6; }

.recipe-headline {
  margin-bottom: 35px;
  @include font-size(30,30);
  @include font-bold;
  color: $headline-color; }

.portion {
  margin-bottom: 30px;
  @include font-size(24,24);
  @include font-normal;
  color: $headline-color;
  vertical-align: middle;
  &:before {
    content: '';
    display: inline-block;
    margin-right: 40px;
    width: 58px;
    height: 58px;
    @include sprite('sprite.svg',0, -63px);
    vertical-align: middle; } }

.recommend-container {
  padding-top: 35px;
  border-top: 1px solid $border-color;
  @include font-size(16,16);
  @include font-normal;
  color: #414146;
  vertical-align: middle; }
.recommend-links-container {
  padding: 30px 0 0; }
.recommend-link {
  display: inline-block;
  margin-left: 20px;
  @include font-size(18,18);
  @include font-normal;
  color: #414146;
  vertical-align: middle;
  text-decoration: none;
  @include transition(opacity);
  &:hover {
    opacity: 0.7; }
  &:first-child {
    margin-left: 0; } }

.recommend-link-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  vertical-align: middle;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 1px solid $border-color;
  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    @include sprite('sprite.svg',0,0); }
  &.fb:before {
    background-position: -250px -27px;
    height: 25px; }
  &.tw:before {
    background-position: -282px -30px; }
  &.google:before {
    background-position: -279px -55px; } }

.recipe-ingredients {
  .container {
    padding-bottom: 40px; } }

.recipe-ingredients-title {
  padding-bottom: 20px;
  margin-bottom: 20px;
  @include font-size(24,24);
  @include font-bold;
  color: $headline-color;
  border-bottom: 1px solid $border-color;
  span {
    @include font-size(18,18);
    @include font-normal; } }

.recipe-ingredients-list {
  margin-bottom: 50px; }

.recipe-ingredient-item {
  margin-bottom: 20px;
  @include font-size(18,18);
  @include font-normal;
  position: relative;
  padding-left: 30px;
  &:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    @include sprite('sprite.svg',-285px, -5px);
    position: absolute;
    top: 4px;
    left: 0; } }

.preparation-headline {
  @include font-size(30,30);
  @include font-bold;
  color: $headline-color;
  margin-bottom: 50px; }

.preparation-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  .col-inline-xs-4 {
    margin-bottom: 20px; } }

.preparation-step {
  padding: 30px;
  border: 1px solid $style-brown-color;
  height: 100%; }

.step-number {
  display: inline-block;
  padding: 0 10px 15px;
  margin-bottom: 20px;
  @include font-size(48,48);
  @include font-normal;
  color: #ebebeb;
  border-bottom: 2px solid #ebebeb; }
.step-text {
  @include font-size(18,24);
  @include font-normal; }

.recipe-tagline {
  @include font-size(36,36);
  color: $headline-color;
  padding: 100px 0 25px;
  span {
    display: block;
    @include font-bold;
    color: $style-green-color; } }

.popup {
  max-width: 820px;
  max-height: 100%; }
.popup-content {
  padding: 0 60px 0 10px;
  background-color: transparent;
  box-shadow: none; }
.popup-title {
  margin-bottom: 50px;
  @include font-size(30,36);
  @include font-bold;
  color: #fff;
  text-align: center;
  letter-spacing: 2px; }
.popup-content-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
.popup-content .popup-close {
  top: 0;
  right: -45px;
  width: 30px;
  height: 30px;
  @include sprite('sprite.svg',-69px, -72px);
  &:before, &:after {
    display: none; } }
.popup-container {
  background-color: rgba(0, 0, 0, 0.6); }



