/*product catalog*/
.catalog-product {
  .container {
    background-repeat: no-repeat;
    background-size: 100%; }
  .content-container {
    max-width: 1145px; }
  .section-headline {
    margin-bottom: 30px; } }

.nav-col {
  width: 226px; }
.content-col {
  width: -webkit-calc(100% - 226px);
  width: calc(100% - 226px); }
.catalog-item {
  display: block;
  height: 335px;
  background-color: #fff;
  border: 1px solid $border-color;
  margin-bottom: 20px;
  color: $headline-color;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  @include transition(all);
  &:hover {
    border-color: transparent;
    box-shadow: 2px 6px 8px rgba(184, 171, 149, 0.65); }
  .img-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    img {
      max-width: 100.9%;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: scale(1.03); } } } }

.catalog-item-headline {
  padding-bottom: 10px;
  margin-top: 35px;
  @include font-size(18,18);
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: $style-brown-color;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -30px; } }
.recommends-product {
  padding: 10px;
  background-color: #dfd2bc;
  font-size: 20px;
  font-weight: 800;
  position: relative;
  z-index: 1;
  spsn {
    margin: 0 5px; }
  &:after, &:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url(/img/sprite.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    transform: scale(0.7); } }
.bay-section {
  .section-headline {
    margin-bottom: 0; } }
.bay-row {
  margin: 0;
  display: flex;
  align-items: stretch;
  [class^=col-inline-xs-] {
    padding: 0; }
  .select-col {
    padding: 35px 55px 35px 0;
    background-color: #f4f4f4;
    .sbHolder {
      width: 100%;
      display: block;
      margin-bottom: 15px; } } }
.select-container {
  display: inline-block;
  width: 100%;
  max-width: 320px; }
.map {
  width: 100%;
  height: 100%;
  min-height: 625px; }

.region-info {
  max-width: 320px;
  display: inline-block; }
.region-item {
  margin-top: 40px; }
.region-item-headline {
  @include font-size(18,18);
  @include font-bold;
  color: $style-green-color;
  margin-bottom: 25px; }
.region-subitem {
  padding-left: 20px;
  margin-top: 35px;
  &:nth-child(2) {
    margin-top: 0; } }
.region-subitem_tel, .region-subitem_mail {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 14px;
    height: 15px;
    position: absolute;
    top: 7px;
    left: -20px;
    @include sprite('sprite.svg',0,0); } }
.region-subitem_tel {
  &:before {
    background-position: -135px -102px; } }
.region-subitem_mail {
  &:before {
    background-position: -112px -102px; } }

/*recipe catalog*/
.catalog-recipe {
  .content-container {
    max-width: 1155px; } }
.catalog-recipe-item {
  display: block;
  width: 100%;
  height: 320px;
  border: 1px solid $border-color;
  margin-top: 20px;
  color: $headline-color;
  background-color: #fff;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  @include transition(all);
  &:hover {
    border-color: transparent;
    box-shadow: 2px 6px 8px rgba(184, 171, 149, 0.65); }
  .catalog-item-headline {
    margin-top: 0;
    padding: 15px 10px 0;
    height: 88px;
    overflow: hidden;
    @include font-size(24,24);
    @include font-normal;
    color: $headline-color;
    &:before {
      display: none; } }
  .img-container {
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    height: -webkit-calc(100% - 90px);
    height: calc(100% - 90px);
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      max-width: 100.9%;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.03); } } } }

