.partners-page {
  .content-container {
    max-width: 1155px; } }
.partners-row {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 20%;
  display: flex;
  border: 1px solid $border-color;
  background-color: #fff;
  overflow: hidden;
  @include transition(all);
  &:first-child {
    margin-top: 0; }
  &:hover {
    border-color: transparent;
    box-shadow: 2px 6px 8px rgba(184, 171, 149, 0.65);
    .btn {
      opacity: 1;
      visibility: visible; } }
  .img-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    text-align: center;
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: scale(1.03); } }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -14px;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 15px 0;
      border-color: transparent #ffffff transparent transparent;
      transform: translateY(-50%); } } }


.headline-block {
  width: 30%;
  height: 100%;
  padding: 0 15px;
  background-color: $style-green-color;
  background-image: url('#{$image-src}/pattern-bg.png');
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    margin-top: 25px;
    opacity: 0;
    visibility: hidden;
    @include transition(all); } }

.row-headline {
  @include font-size(24,24);
  @include font-bold;
  color: #ffffff;
  text-transform: uppercase; }
