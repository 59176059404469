$font-src: '/css/fonts';

@font-face {
  font-family: 'Calibri';
  src: url('#{$font-src}/Calibri-Light.woff') format('woff'), url('#{$font-src}/calibril-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Calibri';
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-src}/calibril-light.eot');
  src: url('#{$font-src}/calibril-light.eot?#iefix') format('embedded-opentype'); }


@font-face {
  font-family: 'Calibri';
  src: url('#{$font-src}/Calibri.woff') format('woff'), url('#{$font-src}/CALIBRI.ttf') format('truetype');
  font-weight: 500;
  font-style: normal; }


@font-face {
  font-family: 'Calibri';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-src}/CALIBRI.TTF.eot');
  src: url('#{$font-src}/CALIBRI.TTF.eot?#iefix') format('embedded-opentype'); }


@font-face {
  font-family: 'Calibri';
  src: url('#{$font-src}/Calibri-Bold.woff') format('woff') url('#{$font-src}/CALIBRI-bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Calibri';
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-src}/CALIBRI-bold.TTF.eot') format('embedded-opentype');
  src: url('#{$font-src}/CALIBRI-bold.TTF.eot?#iefix') format('embedded-opentype'); }



