.trademark-page {
  position: relative;
  .container {
    background-repeat: no-repeat; }
  .content-container {
    max-width: 1155px; } }

.article-section {
  font-size: 0;
  .container {
    padding: 0 15px 40px;
    z-index: 2; }
  .content-container {
    max-width: 1155px; }
  img {
    display: inline-block;
    width: 50%;
    vertical-align: top; }
  .text-container {
    display: inline-block;
    padding-left: 15px;
    width: 50%;
    vertical-align: top;
    @include font-size(16,24); } }
.trademark-section {
  background-color: #ebe7e4;
  padding-top: 50px; }
.trademark-section {
  .container {
    background-repeat: repeat-y;
    padding-bottom: 40px; }
  .content-container {
    max-width: 1155px; } }
.trademark-category {
  padding: 40px 0;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 49px;
    background-image: url('#{$image-src}/gradient.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
  &:first-child {
    padding-top: 0;
    &:before {
      display: none; } }
  &:last-child {
    padding-bottom: 0; } }
.trademark-headline {
  display: inline-block;
  padding-bottom: 25px;
  margin-bottom: 25px;
  @include font-size(24,24);
  @include font-bold;
  color: $headline-color;
  text-transform: uppercase;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $headline-color;
    position: absolute;
    bottom: 0;
    left: 0; } }
.trademark-product-container {
  font-size: 0;
  padding-top: 20px; }
.trademark-category-product {
  width: 135px;
  display: inline-block;
  vertical-align: top;
  padding: 0 6px;
  margin-top: 20px;
  .img-container {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid $border-color;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      transition: transform 2000ms cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.05); } } }
  .name {
    margin-top: 15px;
    @include font-size(14,12);
    text-align: center; } }
.about-trademark-category {
  margin-top: 40px;
  columns: 2;
  li {
    margin-bottom: 10px;
    @include font-size(16,24);
    @include font-normal;
    position: relative;
    padding-left: 30px;
    padding-right: 10px;
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      @include sprite('sprite.svg',-285px, -5px);
      position: absolute;
      top: 4px;
      left: 0; } } }
.packing-table {
  .container {
    padding-top: 40px;
    z-index: 2; }
  .content-container {
    max-width: 1155px; } }
.packing-table-img {
  display: block;
  margin: 0 auto; }
.packing-table-container {
  position: relative;
  margin-top: 40px;
  font-size: 0;
  .table-container {
    display: inline-block;
    width: -webkit-calc(100% - 115px);
    width: calc(100% - 115px);
    table {
      width: 100%; } } }
.fixed-table {
  display: inline-block;
  vertical-align: top;
  width: 115px;
  tr {
    &:first-child {
      td {
        background-color: transparent;
        border-bottom: none; } } }
  td {
    @include font-size(12,14);
    @include font-normal;
    color: $style-brown-color;
    height: 50px;
    vertical-align: middle;
    background-color: #ebe7e4;
    padding: 0 15px;
    border-bottom: 1px solid #e5e3e1; } }
.scroll-table {
  th {
    width: 165px;
    position: relative;
    vertical-align: middle;
    div {
      padding: 0 10px;
      @include font-size(14,14);
      @include font-normal;
      color: #ebe7e4;
      background-color: #625547;
      vertical-align: middle;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center; }
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 80%;
      background-color: #cdcbca;
      position: absolute;
      top: 4px;
      left: 0; }
    &:first-child {
      &:before {
        display: none; } } }
  tr {
    border-bottom: 1px solid $border-color;
    td {
      height: 50px;
      width: 165px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 80%;
        background-color: $border-color;
        position: absolute;
        top: 4px;
        left: 0; }
      &:first-child {
        &:before {
          display: none; } }
      &.plus {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          @include sprite('sprite.svg',-272px, -89px); } } } } }




