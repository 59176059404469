.error-page {
  .container {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      margin-top: 30px; } } }
.code {
  margin-top: 10px;
  @include font-size(122,122);
  @include font-bold;
  color: $headline-color; }
.about-error {
  @include font-size(30,30);
  color: $headline-color; }
