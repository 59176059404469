.block-other {
  background-color: #f4f4f4; }
.other-col {
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 0 10px; }
.other-item {
  width: 100%;
  text-align: center;
  .img-container {
    width: 100%;
    height: 260px;
    border: 1px solid $border-color;
    margin-bottom: 20px; } }
.product-block-other {
  .other-item {
    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      overflow: hidden;
      img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
        &:hover {
          transform: scale(1.03); } } }
    .text-container {
      padding: 0 5px; } } }
.recipe-block-other {
  .other-item {
    .img-container {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      display: block;
      &:hover {
        transform: scale(1.03); } } } }
.other-item-category {
  margin-bottom: 10px;
  @include font-size(14,14);
  @include font-normal;
  color: $style-green-color; }
.other-item-name {
  @include font-size(18,18);
  @include font-normal;
  color: #3d2f17;
  text-decoration: none;
  @include transition(opacity);
  &:hover {
    opacity: 0.7; } }
