.about-page {
  .breadcrumb-section {
    padding: 0;
    .container {
      padding-bottom: 30px;
      background-repeat: no-repeat;
      background-size: 100%; } }
  .section-headline {
    margin-bottom: 35px; }
  .container {
    background-repeat: no-repeat;
    background-size: 100%; }
  .content-container {
    max-width: 1155px; } }
.about-company-title {
  max-width: 455px;
  width: 100%;
  height: 0;
  padding-bottom: 55%;
  position: absolute;
  background-color: #000000;
  left: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    left: 20px;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.1); }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    opacity: 0.9; }
  span {
    display: block;
    width: -webkit-calc(100% - 35px);
    width: calc(100% - 35px);
    padding-bottom: 15px;
    position: absolute;
    bottom: 20px;
    left: 35px;
    @include font-size(24,24);
    @include font-bold;
    color: #fff;
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-width: 355px;
      height: 2px;
      background-color: $style-brown-color;
      position: absolute;
      bottom: 0; } } }
.about-company-item {
  font-size: 0;
  margin-bottom: 30px;
  .text-container {
    display: inline-block;
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
    padding-left: 25px;
    img {
      width: 31px;
      vertical-align: top;
      margin-left: 20px; } } }

.about-company-img {
  width: 60px;
  display: inline-block; }

.about-company-headline {
  @include font-size(24,24);
  @include font-normal;
  color: $headline-color;
  padding-bottom: 10px;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    max-width: 265px;
    height: 1px;
    background-color: #e7e3db;
    position: absolute;
    bottom: 0; } }
.about-company-description {
  @include font-size(16,16);
  padding-top: 5px; }
.values-section {
  background-color: #ebe7e4;
  padding: 45px 0;
  .content-container {
    max-width: 1155px; } }
.values-title {
  margin-bottom: 25px;
  max-width: 355px;
  padding-bottom: 25px;
  @include font-size(24,36);
  @include font-bold;
  color: $headline-color;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $style-brown-color;
    position: absolute;
    bottom: 0;
    left: 0; } }
.values-item {
  max-width: 210px;
  margin: 0 auto; }
.values-item-img {
  margin-bottom: 25px; }
.values-item-description {
  @include font-size(16,16);
  color: #4c4f52; }
.confidence-container {
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 10px;
  box-shadow: 0 0 27px rgba(74, 55, 40, 0.10);
  position: relative;
  top: -30px;
  background-color: #fff; }

.confidence-text {
  @include font-size(36,36);
  margin-bottom: 30px;
  span {
    display: block;
    @include font-bold;
    color: $style-green-color; } }
.download-block {
  display: inline-block;
  margin-top: 50px;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-left: 60px; } }

.download-block-title {
  @include font-size(18,18);
  @include font-bold;
  vertical-align: top;
  color: $headline-color; }
.download-block-item {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  margin-bottom: 20px;
  .text-container {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px; } }
.download-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: top; }
.download-link {
  @include font-size(18,18);
  color: $style-green-color; }
.size {
  @include font-size(12,12);
  span {
    margin-left: 10px; } }
