.article {
  max-width: 930px;
  margin: 0 auto;
  p {
    @include font-size(18,24);
    margin-top: 40px;
    color: $primary-color;
    &:first-child {
      margin-top: 0; } } }
img {
  max-width: 100%;
  height: auto;
  outline: none;
  &:focus {
    outline: none; } }

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  @include font-bold; }

h1 {
  @include font-size(30,30); }

h2 {
  @include font-size(24,30); }

h3 {
  @include font-size(20,24); }

h4, h5, h6 {
  @include font-size(18,20); }

b, strong {
  @include font-bold; }

p {
  display: block;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0; }
ul {
  list-style-type: none;
  li {
    list-style-image: url(data:0);
    list-style-position: outside;
    overflow: hidden; } }

@media (max-width: 865px) {
  .article p {
    margin-top: 20px; } }
@media (max-width: 545px) {
  .article p {
    @include font-size(14,18); }
  h1 {
    @include font-size(24,30); }
  h2 {
    @include font-size(20,24); }
  h3 {
    @include font-size(18,20); }
  h4, h5, h6 {
    @include font-size(16,18); } }

