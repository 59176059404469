@import "sass-partials/variables";
@import "sass-partials/fonts";
/*---------Customs---------*/

* {
  box-sizing: border-box; }
html,
body {
  position: relative;
  width: 100%;
  min-width: 300px;
  height: 100%;
  font-size: 16px;
  line-height: 1.5; }

body {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  color: $primary-color;
  @include font-light;
  background: $primary-background; }

.inView {
  opacity: 0;
  &.mobile {
    opacity: 1; } }

.header,
.footer {
  display: table-row;
  width: 100%; }

main {
  display: table-row;
  width: 100%;
  height: 100%; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.clear {
  clear: both; }

.fixed {
  width: 100%;
  position: fixed!important;
  top: 0;
  z-index: 9; }

.flex-video {
  height: 0;
  margin-bottom: 0.88889rem;
  overflow: hidden;
  padding-bottom: 67.5%;
  padding-top: 1.38889rem;
  position: relative;
  iframe, object, embed, video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

.table-container {
  overflow-y: auto;
  width: 100%; }

ul {
  list-style-type: none;
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  padding: 0; }

figure {
  margin: 0;
  font-size: 0;
  line-height: 1; }

.btn-container {
  text-align: center; }

.btn {
  display: inline-block;
  width: 200px;
  padding: 13px 5px;
  @include font-size(16,16);
  @include font-normal;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: $style-brown-color;
  border-radius: $radius;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  @include transition(all);
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);
    background-color: $hover-color; } }


/*Section*/
section {
  position: relative;
  .container {
    padding-bottom: 40px; } }
.content-container {
  max-width: 990px;
  width: 100%;
  margin: 0 auto; }

.no-padding {
  padding: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.no-padding-top {
  padding-top: 0; }

.section-headline {
  padding: 20px 0;
  margin-bottom: 50px;
  background-color: #dfd2bc;
  background-image: url('#{$image-src}/pattern-bg.png');
  @include font-size(36,36);
  color: $section-headline-color;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  @include transition(all, 1.5, 0.5);
  &:before, &:after {
    content: '';
    display: inline-block;
    width: 40px;
    height: 40px;
    @include sprite('sprite.svg',0,0);
    vertical-align: middle; }
  span {
    vertical-align: middle;
    margin: 0 35px; }
  &.green {
    background-color: #b6cf8d; }
  &.inView {
    padding: 0;
    height: 1px;
    &:before,
    &:after {
      opacity: 0;
      @include transition(all, 1.5, 1.5); }
    &.animate {
      padding: 20px 0;
      height: 80px;
      &:before,
      &:after {
        opacity: 1; } }
    &.mobile {
      height: auto;
      padding: 20px 0;
      &:before,
      &:after {
        opacity: 1; } } } }
h1.section-headline {
  @include font-light; }
.next-section {
  position: absolute;
  bottom: 40px;
  left: 95px;
  transform: rotate(-90deg);
  transform-origin: 10px; }
.arrow-icon {
  display: inline-block;
  width: 10px;
  height: 16px;
  @include sprite('sprite.svg',-224px, -3px);
  margin-right: 5px;
  vertical-align: middle; }
.next-section-name {
  @include font-size(12,12);
  color: #87714d;
  text-transform: uppercase;
  vertical-align: middle;
  &:before, &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 2px;
    background-color: $style-brown-color; }
  &:before {
    margin-right: 10px; }
  &:after {
    margin-left: 10px; } }
/*Section*/

/*breadcrumbs*/
.breadcrumb-section {
  padding-bottom: 30px; }

.breadcrumbs {
  padding-top: 30px; }
.breadcrumbs-list {
  font-size: 0;
  margin: 0 15px; }
.breadcrumbs-child {
  display: inline-block;
  vertical-align: middle;
  padding-right: 2px;
  @include font-size(14,14);
  @include font-normal;
  color: #a6a6a6;
  &:last-child {
    .breadcrumbs-link {
      color: $style-green-color; } } }
.breadcrumbs-link {
  color: #a6a6a6;
  text-decoration: none; }

/*breadcrumbs*/

/*Carousel*/
.slick-prev, .slick-next {
  width: 17px;
  height: 28px;
  @include sprite('sprite.svg',0,0);
  &:before {
    display: none; } }
.slick-prev {
  background-position: -57px -29px;
  z-index: 1;
  left: 0;
  &:hover, &:focus {
    @include sprite('sprite.svg',-57px, -29px); } }
.slick-next {
  background-position:  -85px -29px;
  right: 0;
  &:hover, &:focus {
    @include sprite('sprite.svg',-85px, -29px); } }
.slick-dots {
  li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border: 1px solid transparent;
    border-radius: 50%;
    margin: 8px;
    @include transition(all);
    button {
      padding: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #898c8e;
      &:before {
        display: none; } }
    &.slick-active, &:hover {
      border-color: $style-green-color; } } }
/*Carousel*/

/*Pagination*/
.pagination {
  text-align: center;
  font-size: 0;
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px;
    @include font-size(24,24);
    @include font-normal;
    a {
      color: $headline-color;
      text-decoration: none; }
    &.active, &:hover {
      a, span {
        color: $style-green-color; } }
    &:first-child, &:last-child {
      a, span {
        display: inline-block;
        width: 27px;
        height: 27px;
        vertical-align: middle;
        font-size: 0; }
      @include sprite('sprite.svg', -244px, -53px); }
    &:first-child {
      transform: rotate(180deg); } } }

/*Pagination*/

/*Forms*/
textarea {
  resize: none;
  overflow: hidden;
  min-height: 0; }

select {
  outline: 0;
  border-radius: $radius;
  padding: 15px 50px 15px 25px;
  box-sizing: border-box;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-indent: 0;
  color: #c2c2c3;
  @include font-size(18,18);
  border: none;
  background-image: url('#{$image-src}/select-arrow.png');
  background-position: 95% 50%;
  background-repeat: no-repeat; }

.sbHolder {
  background-color: #FFF;
  box-shadow: 0 0 27px rgba(135, 113, 77, 0.15);
  border-radius: $radius;
  border: none;
  text-align: left;
  height: 50px; }
.sbToggle {
  background-image: url(/img/select-arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  right: 10px;
  &:hover {
    background: url(/img/select-arrow.png) no-repeat center; } }
.sbSelector {
  width: -webkit-calc(100% - 45px);
  width: calc(100% - 45px);
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  @include font-light;
  &:link, &:visited, &:hover {
    color: #c2c2c3; } }
.sbOptions {
  width: 100%;
  max-width: none;
  background-color: #fff;
  border-radius: 25px;
  border: none;
  box-shadow: 0 0 27px rgba(135, 113, 77, 0.15);
  padding: 20px;
  top: 106% !important;
  li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(194, 194, 195, 0.2); }
  a {
    color: #c2c2c3;
    border-bottom: none;
    @include font-size(14,14);
    &:link, &:visited {
      color: #c2c2c3; } } }

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.30);
  text-overflow: ellipsis;
  @include font-size(18,18);
  transition: opacity 0.8s; }
::-moz-placeholder {
  color: rgba(255, 255, 255, 0.30);
  text-overflow: ellipsis;
  opacity: 1;
  @include font-size(18,18);
  transition: opacity 0.8s; }

::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.30);
  text-overflow: ellipsis;
  @include font-size(18,18);
  transition: opacity 0.8s; }
/*Forms*/

/*Article*/

.article {
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0; } }
  p, img, ul, ol {

    &:first-child {
      margin-top: 0; } }
  ul {
    list-style-type: none; } }
/*Article*/

/*Page*/
.page-header {
  h1 {} }
/*Page*/

/*category-links*/
.category-links-container {
  width: 850px;
  margin: 0 auto;
  font-size: 0; }
.category-links-item {
  display: inline-block;
  width: 140px;
  padding: 0 4px;
  margin-bottom: 15px;
  vertical-align: middle; }
.category-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 10px 0;
  @include font-size(12,12);
  @include font-normal;
  color: $style-brown-color;
  text-align: center;
  text-decoration: none;
  border: 1px solid $style-brown-color;
  border-radius: $radius;
  @include transition(all);
  &:hover {
    @include font-bold;
    box-shadow: 0 6px 8px rgba(184, 171, 149, 0.65); } }
/*category-links  */

.content_page {
  padding-top: 60px; }
/*---------Customs---------*/
@import "sass-partials/header";
@import "sass-partials/footer";
@import "sass-partials/block-other";
@import "sass-partials/main-page";
@import "sass-partials/transition";
@import "sass-partials/product";
@import "sass-partials/catalog";
@import "sass-partials/category";
@import "sass-partials/recipe-page";
@import "sass-partials/about-page";
@import "sass-partials/trademark";
@import "sass-partials/contacts";
@import "sass-partials/news";
@import "sass-partials/article";
@import "sass-partials/error";
@import "sass-partials/partners";
@import "sass-partials/search-result";
@import "sass-partials/ready-meals";
@import "sass-partials/b2b";
@import "sass-partials/responsive";
