.trademark-section {
  .section-headline {
    background-color: transparent;
    margin-bottom: 5px; }
  &.white {
    background-color: transparent;
    .section-headline {
      &:before, &:after {
        background-position: -105px -55px; } } } }
.section-description {
  margin: 0 auto 50px;
  max-width: 690px;
  text-align: center; }

