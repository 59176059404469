.schema {
  max-width: 945px;
  margin: 40px auto 0;
  padding: 0 10px;
  overflow: hidden; }
.schema-row {
  padding: 35px 0 25px;
  color: #ffffff;
  &:before {
    content: '';
    display: block;
    width: 66%;
    height: 1px;
    background-color: #707070;
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .col-inline-xs-12 {
    padding: 0 10px;
    @include font-size(18,13.5);
    @include font-normal;
    background-color: $style-green-color;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-radius: 35px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      background-color: #707070;
      position: absolute;
      bottom: -20px;
      left: 50%; }
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 9px 0 9px;
      border-color: #707070 transparent transparent transparent;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%); } }
  &:first-child {
    &:before {
      display: none; }
    .col-inline-xs-12 {
      width: 70%;
      &:after {
        display: none; } } }
  &:last-child {
    &:before {
      display: none; }
    .col-inline-xs-12 {
      background-color: $style-brown-color;
      &:before {
        bottom: auto;
        top: -20px; }
      &:after {
        content: '';
        display: block;
        width: 27px;
        height: 27px;
        @include sprite('sprite.svg',-158px, -57px);
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translateX(-50%);
        border: none; } } }
  .col-inline-xs-4 {
    @include font-size(18,13.5);
    @include font-normal; } }
.schema-item {
  padding: 0 10px;
  max-width: 270px;
  margin: 0 auto;
  background-color: $style-brown-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 35px;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    background-color: #707070;
    position: absolute;
    bottom: -20px;
    left: 50%; }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 9px 0 9px;
    border-color: #707070 transparent transparent transparent;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%); } }
.plus-item {
  width: 25%;
  padding: 0 50px;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 27px;
    height: 27px;
    @include sprite('sprite.svg',-203px, -56px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5%; }
  &:first-child {
    &:before {
      display: none; } } }


