.contact-page {
  .container {
    padding-bottom: 40px; }
  .content-container {
    max-width: 1155px; } }
.contact-data {
  max-width: 1010px;
  margin: 0 auto 40px;
  font-size: 0; }
.contact-item {
  display: inline-block;
  @include font-size(16,24);
  width: 33.33333%;
  padding: 0 30px;
  vertical-align: top;
  span, a {
    color: $primary-color;
    text-decoration: none; } }
.item-container {
  padding-left: 45px;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    @include sprite('sprite.svg',0,0);
    position: absolute;
    top: 0;
    left: 0; }
  &.address {
    &:before {
      background-position: -233px -85px; } }
  &.contact-tel {
    &:before {
      background-position: -160px -85px; } }
  &.contact-email {
    &:before {
      background-position: -196px -84px; } } }


.contact-map {
  width: 100%;
  height: 410px; }
