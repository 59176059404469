.news-catalog {
  .container {
    padding-bottom: 20px; }
  .row-inline {
    margin: 0 -30px; }
  .col-inline-xs-4 {
    padding: 0 30px;
    margin-bottom: 60px; } }


/*news page*/
.news-page {
  .catalog-item {
    background-repeat: no-repeat; } }

.news-img {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 45%;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block; } }
.recommend-block {
  max-width: 1155px;
  margin: 50px auto 0;
  background-color: #ebe7e4;
  text-align: center;
  padding: 40px 0;
  .recommend-container {
    padding: 0;
    border: none;
    @include font-size(24,24);
    @include font-normal; }
  .recommend-link {
    @include font-size(18,18);
    @include font-normal; }
  .recommend-link-icon {
    background-color: #fff;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16); } }
