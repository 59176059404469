.category-page {
  .breadcrumb-section {
    padding: 0;
    .container {
      padding-bottom: 30px;
      background-repeat: no-repeat;
      background-size: 100%; } }
  .section-headline {
    margin-bottom: 30px; }
  .container {
    background-repeat: no-repeat;
    background-size: 100%; } }
.about-category {
  width: 100%;
  font-size: 0;
  margin-bottom: 20px;
  .img-container {
    display: inline-block;
    width: 50%;
    vertical-align: top; }
  .text-container {
    display: inline-block;
    width: 50%;
    padding: 0 10px 0 20px;
    @include font-size(16,24);
    vertical-align: top; } }

.category-row {
  .nav-catalog {
    top: 0; } }
.category-product {
  max-width: 925px;
  margin: 0 0 0 15px;
  .img-container {
    height: 215px;
    img {
      max-height: 100%; } }
  .catalog-item {
    padding: 0 10px;
    text-align: center; } }
.weight {
  margin-top: 10px;
  @include font-size(14,14); }
