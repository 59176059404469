.search-result {
  .container {
    background-repeat: repeat-y; }
  .content-container {
    max-width: 1155px; }
  form {
    font-size: 0; } }
.search-btn-container {
  display: inline-block;
  width: 200px;
  vertical-align: middle; }
.input-container {
  display: inline-block;
  width: -webkit-calc(100% - 200px);
  width: calc(100% - 200px);
  padding-right: 30px;
  input {
    width: 100%;
    padding: 13px 30px;
    border: 1px solid #959595;
    border-radius: $radius;
    @include font-size(16,16);
    @include font-normal;
    vertical-align: middle;
    &::-webkit-input-placeholder {
      color: #4c4b4e;
      @include font-size(16,16); }
    &::-moz-placeholder {
      color: #4c4b4e;
      @include font-size(16,16); }

    &::-ms-input-placeholder {
      color: #4c4b4e;
      @include font-size(16,16); } } }
.result-div {
  margin-top: 15px;
  padding-left: 30px;
  @include font-size(14,14);
  @include font-normal;
  span {
    @include font-bold; } }
.search-result-container {
  margin-top: 50px; }
.search-result-item {
  display: block;
  font-size: 0;
  padding: 25px 0 15px;
  border-top: 1px solid #959595;
  text-decoration: none;
  color: $primary-color;
  &:first-child {
    border-top: none; }
  .number {
    display: inline-block;
    width: 45px;
    @include font-size(24,24);
    @include font-normal;
    color: $style-green-color;
    vertical-align: top; }
  .text-container {
    display: inline-block;
    width: -webkit-calc(100% - 45px);
    width: calc(100% - 45px);
    @include font-size(16,18);
    max-height: 54px;
    overflow: hidden;
    vertical-align: top; }
  .img-container {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    img {
      width: auto;
      height: 54px; } } }
.search-result-name {
  display: inline-block;
  width: -webkit-calc(100% - 156px);
  width: calc(100% - 156px);
  vertical-align: top;
  @include font-size(16,16);
  padding-left: 15px;
  .category {
    display: block;
    color: $style-green-color;
    margin-bottom: 10px; } }
