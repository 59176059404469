$primary-color: #98999b;
$primary-background: #fff;
$headline-color: #54565b;
$style-green-color: #006938;
$style-brown-color: #87714d;
$section-headline-color: #404147;
$menu-border-color: #296138;
$hover-color: #9a8769;
$border-color: #eae7e3;
$radius: 25px;
$image-src: '/img';

@mixin transition($property, $time: 0.3, $delay: 0) {
  -webkit-transition: $property $time+s ease $delay+s;
  transition: $property $time+s ease $delay+s; }

@mixin sprite($name, $x, $y) {
  background-image: url('#{$image-src}/#{$name}');
  background-repeat: no-repeat;
  background-position: $x $y; }

@mixin font-light {
  font-family: 'Calibri', sans-serif;
  font-weight: 300;
  font-style: normal; }

@mixin font-normal {
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  font-style: normal; }

@mixin font-bold {
  font-family: 'Calibri', sans-serif;
  font-weight: 800;
  font-style: normal; }


@mixin rotate($angle) {
  -webkit-transform: rotate(#{$angle}deg);
  -moz-transform: rotate(#{$angle}deg);
  -o-transform: rotate(#{$angle}deg);
  -ms-transform: rotate(#{$angle}deg);
  transform: rotate(#{$angle}deg); }


@mixin scale($koef) {
  -webkit-transform: scale($koef);
  -moz-transform: scale($koef);
  -o-transform: scale($koef);
  -ms-transform: scale($koef);
  transform: scale($koef); }


@mixin font-size($fontSize, $lineHeight) {
  //font-size: #{$fontSize/16}rem
  font-size: #{$fontSize}px;
  line-height: $lineHeight/$fontSize; }

