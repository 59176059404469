.about-product {
  .container {
    background-repeat: no-repeat;
    background-position: 90% 0; } }

.nav-catalog {
  position: relative;
  top: -15px; }

.nav-catalog-headline {
  padding: 20px 5px 20px 20px;
  background-image: url('#{$image-src}/pattern-bg.png');
  background-size: cover;
  background-color: $style-brown-color;
  @include font-size(18,18);
  @include font-bold;
  color: #fff;
  position: relative; }
.nav-catalog-headline_arrow {
  display: none;
  width: 18px;
  height: 10px;
  @include sprite('sprite.svg',-25px, -42px);
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -5px; }
.nav-catalog-container {
  padding: 10px 0;
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: 1px 7px 8px rgba(36, 36, 36, 0.05);
  @include transition(height, 0.5); }

.nav-catalog-item {
  padding: 15px 15px 15px 20px;
  @include transition(all);
  &.is-hover:hover, &.open {
    background-color: #fbfbfb;
    .nav-catalog-body {
      height: auto;
      overflow: visible;
      opacity: 1; }
    .nav-catalog-link {
      color: $style-brown-color; }
    .nav-catalog-arrow {
      transform: rotate(0); } } }

.nav-catalog-item-headline {
  position: relative; }

.nav-catalog-link {
  @include font-size(18,18);
  color: $headline-color;
  text-decoration: none;
  @include transition(all); }

.nav-catalog-body {
  height: 0;
  overflow: hidden;
  opacity: 0;
  @include transition(all); }

.nav-catalog-arrow {
  display: block;
  width: 8px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: 0;
  @include sprite('sprite.svg',-225px, -4px);
  transform: rotate(180deg);
  @include transition(transform); }

.nav-catalog-body {
  padding-left: 15px; }
.nav-catalog-sub_item {
  line-height: 1;
  padding-bottom: 10px; }
.nav-catalog-sub_link {
  @include font-size(14,14);
  color: $headline-color;
  text-decoration: none;
  &:hover {
    color: $style-brown-color; } }

.product-info-row {
  margin: 55px 0 0; }

.product-info-col {
  display: inline-block;
  vertical-align: top; }

.product-img-col {
  width: 35%;
  padding-left: 55px; }

.product-data-col {
  width: 65%;
  padding-left: 50px; }

.product-name {
  @include font-size(30,30);
  @include font-bold;
  color: $headline-color;
  margin-bottom: 30px; }

.product-about {
  width: 100%;
  max-width: 500px;
  padding-right: 5px;
  @include font-size(16,24);
  @include font-normal;
  margin-bottom: 30px; }

.ingredients-container {
  margin-bottom: 50px; }

.ingredients-title {
  @include font-size(16,16);
  @include font-normal;
  color: $style-green-color; }

.ingredients-items {
  display: inline-block;
  @include font-size(14,14);
  @include font-normal;
  color: $headline-color;
  width: 90px;
  text-align: center;
  margin: 10px 0;
  vertical-align: middle; }

.in-product-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 0;
  padding: 5px 27px;
  border-radius: $radius;
  background-color: #dfd2bc;
  color: #3d2f17;
  &.vitamins {
    .parameter {
      width: 20%; }
    .value {
      width: 80%; } } }


.parameter {
  display: inline-block;
  vertical-align: middle;
  @include font-size(16,16); }

.value {
  display: inline-block;
  vertical-align: middle;
  @include font-size(16,16);
  @include font-normal;
  max-width: 80%;
  padding-left: 10px;
  span {
    margin-left: 8px;
    padding: 2px 0;
    white-space: nowrap; } }

.product-img-preview {
  padding: 0 20px; }
.product-img-slide {
  img {
    max-width: none;
    width: 100%;
    height: auto;
    margin: 0 auto; } }
.product-preview-slide {
  text-align: center;
  img {
    display: inline-block; } }

.recipe-with-product {
  width: 100%;
  max-width: 1150px;
  margin: 30px auto 0;
  padding: 2px;
  border: 1px solid $border-color;
  font-size: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  .img-container {
    width: 420px;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      transition: transform 2000ms cubic-bezier(0.215,.61,.355,1);
      &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.03); } } }
  .text-container {
    width: -webkit-calc(100% - 420px);
    width: calc(100% - 420px);
    padding: 35px 45px 35px 75px; }
  .headline {
    margin-bottom: 35px;
    @include font-size(24,24);
    @include font-bold;
    color: $headline-color;
    max-height: 48px;
    overflow: hidden; } }
.block-title {
  margin-bottom: 35px;
  @include font-size(16,16);
  @include font-normal;
  color: $style-brown-color; }
.recipe-with-description {
  margin-bottom: 45px;
  @include font-size(16,24);
  @include font-normal;
  max-height: 74px;
  overflow: hidden; }

.quantity-photo {
  text-align: center;
  font-size: 20px; }





