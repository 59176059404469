.footer-row {
  margin: 0;
  display: flex;
  align-items: stretch;
  [clear^=col-inline-xs] {
    padding: 0; }
  .col-inline-xs-9 {
    background-color: $style-green-color;
    background-size: cover;
    padding: 30px 15px; }
  .col-inline-xs-3 {
    background-color: $style-brown-color;
    padding: 20px 15px 20px 50px; } }
.footer-content {
  width: 100%;
  max-width: 815px;
  margin: 0 auto; }
.footer-content-row {
  margin: 0; }
.footer-content-col {
  display: inline-block;
  vertical-align: middle; }
.footer-logo-col {
  width: 30%;
  padding-right: 40px; }
.footer-menu-col {
  width: 70%;
  padding-left: 40px; }
.footer-logo {
  width: 100%;
  max-width: 165px; }
.copyrighted {
  margin-top: 20px;
  @include font-size(12,12);
  color: #fff; }
.footer-menu-item {
  display: inline-block;
  width: 33.33333%;
  vertical-align: middle;
  padding: 15px 0;
  &:before {
    content: '';
    margin-right: 12px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $style-brown-color;
    vertical-align: middle; } }
.footer-menu-link {
  @include font-size(20,20);
  @include font-normal;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  @include transition(opacity);
  &:hover {
    opacity: 0.6; } }
.hot-line {
  @include font-size(14,14);
  @include font-normal;
  color: #fff;
  a {
    @include font-size(24,24);
    @include font-bold;
    color: #fff;
    display: block;
    text-decoration: none;
    @include transition(opacity);
    &:hover {
      opacity: 0.6; } } }
.soc-links {
  margin: 30px 0; }
.development {
  @include font-size(16,16);
  @include font-normal;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
    @include transition(opacity);
    &:hover {
      opacity: 0.6; } } }
